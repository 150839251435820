import type { Database, User } from '~/supabase'
import { useSupabaseAuthClient } from '#imports'

export async function useFetchUser(userId: string): Promise<User> {
  const supabase = useSupabaseAuthClient<Database>()

  const { data, error } = await supabase
    .from('user')
    .select('*')
    .eq('id', userId)
    .limit(1)
    .single()

  if (error) {
    throw error
  }

  return data
}
